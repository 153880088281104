<template>
  <v-row class="pa-3">
    <v-col v-if="!testPayment.id" cols="12"
      ><v-row>
        <v-col cols="4">
          <v-checkbox label="Enable Test Gateway" v-model="isTestGateway"></v-checkbox>
        </v-col> </v-row
    ></v-col>
    <v-col cols="4" v-if="testPayment.id"><h3>Test Gateway</h3></v-col>
    <v-col cols="8" v-if="testPayment.id"
      ><v-row class="px-3">
        <v-col cols="12" class="pa-0">
          <div class="pa-3 elevation-form">
            <div>
              Test Gateway is meant for testing. LatteHub will not charge you transaction fees on orders paid with this
              gateway.
            </div>
            <span>You can use the</span
            ><a href="https://stripe.com/docs/testing" target="_blank" class="linkTo"> Stripe test cards </a
            ><span> to place order, for example:</span>
            <div>• 4242424242424242 - To Simulate an Approved Transaction</div>
            <div>• 4000000000000002 - To Simulate a Declined Transaction</div>
            <div>
              Any three numbers can be used as the "Card Security Code" and any expiry date in the future will work.
            </div>
            <div class="d-flex justify-space-between mt-3">
              <v-btn class="primary" @click="onActiveCredit(testPayment)">{{
                !testPayment.active ? 'Active' : 'Deactive'
              }}</v-btn>
            </div>
          </div>
        </v-col>
        <!-- <modal
          model="onAddAccount"
          titleButton="Add account"
          :callback="submitCredit"
          title="Add new Test Gateway account"
          width="800"
        >
          <template slot="content">
            <span class="mr-1">* Account Name</span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi mdi-information-outline mdi-18px</v-icon>
              </template>
              <span
                >Enter a name for this account. This name will only be used in LatteHub dashboard and order
                details.</span
              >
            </v-tooltip>
            <v-text-field dense></v-text-field>
            <div>
              Test Gateway is meant for testing. LatteHub will not charge you transaction fees on orders paid with this
              gateway.
            </div>
            <span>You can use the</span
            ><a href="https://stripe.com/docs/testing" target="_blank" class="linkTo"> Stripe test cards </a
            ><span> to place order, for example:</span>
            <div>• 4242424242424242 - To Simulate an Approved Transaction</div>
            <div>• 4000000000000002 - To Simulate a Declined Transaction</div>
            <div>
              Any three numbers can be used as the "Card Security Code" and any expiry date in the future will work.
            </div>
          </template>
        </modal> -->
      </v-row>
    </v-col>
    <v-dialog v-model="onActiveTestDialog" max-width="690">
      <v-card style="position: relative">
        <v-card-title class="text-h5">
          Active Test Gateway
        </v-card-title>
        <div class="close"><v-icon @click="onActiveTestDialog = false">fas fa-times</v-icon></div>
        <v-divider></v-divider>
        <v-card-text class="my-6 py-0"
          >If you turn on the test mode, Stripe account(if any) will be disabled. Please enable it after turning off
          this mode.</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="onActiveTestDialog = false">
            Close
          </v-btn>
          <v-btn color="primary" @click="activeTestGateway(testPayment)">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { paymentService } from '@/apis/payment';
export default {
  props: {
    testPayment: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    // để khi enable lần đầu thì lấy data về
    isTestGateway() {
      if (this.isTestGateway) {
        paymentService.create(this.testGateway);
        this.$parent.loadPayments();
      }
    },
  },
  data() {
    let firstListStripe = this.$parent.paymentMethods
      .filter(m => m.paymentType === 'stripe' && m.isTestPayment == false && m.active == true)
      .map(x => x);
    return {
      onActiveTestDialog: false,
      firstListStripe: firstListStripe, // là các stripe đang active
      onGateway: null,
      testGateway: {
        name: 'Test Gateway',
        clientId: 'TestGateway1234560',
        clientSecret: 'TestGateway1234560',
        isTestPayment: true,
        paymentType: 'test',
        action: true,
      },
      isTestGateway: false,
      disabled: false,
      onAddAccount: false,
    };
  },
  methods: {
    async activeTestGateway(item) {
      console.log(item);
      console.log(this.$parent);
      try {
        let dataGetway = { clientId: 'TestGateway1234560', paymentType: 'test' };
        let a = this.$parent.paymentMethods.filter(
          m => m.paymentType === 'stripe' && m.isTestPayment == false && m.active == true,
        );
        await paymentService.activate(item.id, dataGetway);
        console.log(a);
        if (a) {
          for (let index = 0; index < a.length; index++) {
            const element = a[index];
            let data = {
              clientId: element.clientId,
            };
            await paymentService.deactivate(element.id, data);
          }
        }
        this.$parent.onTestGetway = true;
        this.$parent.loadPayments();
      } catch (error) {
        console.log(error);
        this.onActiveTestDialog = false;
      }
      this.onActiveTestDialog = false;
    },
    async onActiveCredit(item) {
      try {
        let dataGetway = { clientId: 'TestGateway1234560', paymentType: 'test' };
        if (!item.active) {
          this.onActiveTestDialog = true;
        } else {
          await paymentService.deactivate(item.id, dataGetway);
          this.$parent.onTestGetway = false;
          this.$parent.loadPayments();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.linkTo {
  cursor: pointer;
  color: #a98a57 !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
</style>
