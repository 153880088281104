<template>
  <div>
    <v-row class="pa-3">
      <v-col cols="4">
        <h3>Cash On Delivery (COD)</h3>
      </v-col>
      <v-col cols="8">
        <div class="elevation-form pa-3">
          <v-btn @click="onDialogAddCod">+ Add another COD</v-btn>
          <v-card style="position: relative" v-if="onAddCod" class="mt-3">
            <v-divider></v-divider>
            <v-row class="ma-0">
              <v-col cols="12"
                ><h4>Name</h4>
                <v-text-field
                  placeholder="COD Name"
                  v-model="$v.dataCOD.name.$model"
                  :error-messages="invalid && !$v.dataCOD.name.required ? $t('Name is required') : ''"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><Editor title="Information" @change="setDataCOD" type="editor" :data="dataCOD.noteHtml" />
                <div v-if="invalid && this.dataCOD.noteHtml.length == 0" class="red--text">Information is required</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="" @click="onAddCod = false"> Close </v-btn>
              <v-btn color="primary" :disabled="$v.$invalid && $v.$dirty" @click="createCod(dataCOD)"> Save </v-btn>
            </v-card-actions>
          </v-card>
          <v-expansion-panels :class="{ 'my-5': paymentsCod.length > 0 }">
            <v-expansion-panel v-for="(item, ind) in paymentsCod" :key="ind">
              <v-expansion-panel-header
                >COD name:
                <h4 class="ml-1">{{ item.name }}</h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div>
                  <Editor
                    title=" "
                    @change="change"
                    subtitle="Maximum 100.000 characters"
                    :index="{ i: 0, j: ind }"
                    :data="item.noteHtml"
                  />
                  <!-- <div v-if="item.noteHtml.length == 0" class="red--text">Infomation is required</div> -->
                </div>
                <div class="d-flex justify-space-between mt-3">
                  <v-btn color="primary" @click="reActiveCod(item)" :disabled="isLoading">{{
                    item.active ? 'Deactive' : 'Active'
                  }}</v-btn>
                  <div>
                    <v-btn
                      color="primary"
                      @click="editCod(item)"
                      :disabled="isLoading1 || !isEdit || noteHtmlChange.length == 0"
                      >Save</v-btn
                    >
                    <v-btn color="error" @click="removeCod(item.id)" class="ml-3" :disabled="isLoading2">Remove</v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
      <!-- <v-dialog v-model="onAddCod" max-width="890">
        <v-card style="position: relative">
          <v-card-title class="text-h5"> Add another stripe COD </v-card-title>
          <div class="close"><v-icon @click="onAddCod = false">fas fa-times</v-icon></div>
          <v-divider></v-divider>
          <v-row class="ma-0">
            <v-col cols="8" offset="2"
              ><label for="">Name</label><v-text-field v-model="dataCOD.name" hide-details=""></v-text-field
            ></v-col>
            <v-col cols="8" offset="2"><Editor title="COD" @change="setDataCOD"/></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" @click="onAddCod = false"> Close </v-btn>
            <v-btn color="primary" @click="createCod(dataCOD)"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-row>
  </div>
</template>

<script>
import Editor from '../../sizechart/component/Editor';
import { paymentService } from '@/apis/payment';
import { required, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    Editor,
  },
  props: {
    paymentsCod: {
      type: Array,
      default: () => [],
    },
  },
  // watch: {
  //   'dataCOD.active': async function() {
  //     if (this.dataCOD.active == true) {
  //       await paymentService.activate(this.dataCOD.id, this.dataCOD);
  //       console.log('active', this.dataCOD);
  //     } else {
  //       await paymentService.deactivate(this.dataCOD.id, this.dataCOD);
  //       console.log('deactive', this.dataCOD);
  //     }
  //   },
  // },
  // async created() {
  //   let index = this.$parent.paymentMethods.findIndex(i => i.paymentType == 'cod');
  //   if (index > -1) {
  //     this.dataCOD.noteHtml = this.dataCod.noteHtml;
  //     this.dataCOD.active = this.dataCod.active;
  //   } else {
  //     await paymentService.create({
  //       noteHtml: '',
  //       key: 'keyCOD123456',
  //       secretkey: 'secretkeyCOD123456',
  //       paymentType: 'cod',
  //       active: false,
  //     });
  //     this.$parent.loadPayments();
  //   }
  // },
  data() {
    // let id = this.$parent.paymentMethods.find(i => i.paymentType == 'cod').id || '';
    return {
      isEdit: false, // để khi editor có thay đổi thì mới cho save
      invalid: false,
      slotPanel: [0],
      codPayment: false,
      dataCOD: {
        noteHtml: '',
        name: '',
        key: 'keyCOD123456',
        secretkey: 'secretkeyCOD123456',
        paymentType: 'cod',
        active: true,
      },
      onAddCod: false,
      noteHtmlChange: '',
      isLoading: false, // reactive và add
      isLoading1: false, // cho Edit
      isLoading2: false, // cho remove
    };
  },
  methods: {
    async reActiveCod(item) {
      this.isLoading = true;
      if (item.active == true) {
        let data = {
          ...item,
          active: false,
        };
        await paymentService.deactivate(item.id, data);
      } else {
        let data = {
          ...item,
          active: true,
        };
        await paymentService.activate(item.id, data);
      }
      this.$parent.loadPayments();
      this.isLoading = false;
    },
    async editCod(item) {
      this.isLoading1 = true;
      let data = {
        active: item.active,
        id: item.id,
        isTestPayment: item.isTestPayment,
        key: item.key,
        secretkey: item.secretkey,
        paymentType: item.paymentType,
        noteHtml: this.noteHtmlChange,
      };
      await paymentService.editPayment(data.id, data);
      this.$parent.loadPayments();
      // this.noteHtmlChange = '';
      console.log(data);
      console.log(this.noteHtmlChange);
      this.isLoading1 = false;
      this.isEdit = false;
    },
    async removeCod(itemId) {
      this.isLoading2 = true;
      await paymentService.deleteById(itemId);
      this.$parent.loadPayments();
      this.isLoading2 = false;
    },
    change(data, ind) {
      this.isEdit = true; // khi editor thay đổi
      this.noteHtmlChange = data;
      console.log(this.noteHtmlChange);
    },
    setDataCOD(data, ind) {
      this.dataCOD.noteHtml = data;
      console.log(data);
    },
    async createCod(item) {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      try {
        if (!this.$v.$invalid) {
          this.isLoading = true;
          await paymentService.create(item);
          this.$parent.loadPayments();
          this.onAddCod = false;
          this.dataCOD.name = '';
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    onDialogAddCod() {
      this.onAddCod = true;
    },
    getHtml(data, index) {
      this.dataCOD.noteHtml = data;
    },
    async submit() {
      // let a = this.$parent.paymentMethods.find(i => i.paymentType == 'cod');
      // console.log(a);
      let updateInfo;
      console.log(updateInfo);
      if (this.id) {
        updateInfo = {
          ...this.dataCOD,
          id: this.id,
        };
        await paymentService.editPayment(updateInfo.id, updateInfo);
        console.log(1);
      } else {
        await paymentService.create(this.dataCOD);
        // this.codPayment = true;
        console.log(2);
      }
      this.$parent.loadPayments();
    },
  },
  validations: {
    dataCOD: {
      name: {
        required,
      },
      noteHtml: {
        required,
      },
    },
  },
};
</script>

<style>
.info {
  width: 80% !important;
}
</style>
